import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function ComplianceServices() {
  const benefits = [
    'Achieve and maintain compliance with industry standards like HIPAA, SOC 2, PCI DSS, and NIST.',
    'Digital penetration testing to identify and address system vulnerabilities.',
    'Physical penetration testing to evaluate the security of your premises and facilities.',
    'Comprehensive gap assessments to uncover areas needing improvement.',
    'Policy and procedure development for audit readiness and ongoing compliance.',
    'Real-time monitoring and reporting to ensure continued compliance.'
  ];

  return (
    <ServiceLayout
      title="Compliance Services"
      description="Stay ahead of regulatory requirements and protect your business with our compliance solutions. From digital and physical penetration testing to policy development, we help you achieve and maintain compliance with industry standards."
      benefits={benefits}
      isCybersecurity={false} // No scarcity elements for this page
    />
  );
}

export default ComplianceServices;
