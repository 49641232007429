import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, TextField } from '@mui/material';

function BlogAdmin() {
  const [blogs, setBlogs] = useState([]);
  const [form, setForm] = useState({ title: '', summary: '', content: '', author: '', mediaFiles: [], youtubeLinks: [] });
  const [editingId, setEditingId] = useState(null);

  const fetchBlogs = async () => {
    const response = await fetch('http://localhost:3001/blogs');
    const data = await response.json();
    setBlogs(data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleSubmit = async () => {
    const blogFormData = new FormData();
    blogFormData.append('title', form.title);
    blogFormData.append('summary', form.summary);
    blogFormData.append('content', form.content);
    blogFormData.append('author', form.author);

    const blogResponse = await fetch('/blogs', {
      method: 'POST',
      body: blogFormData,
    });
    const newBlog = await blogResponse.json();

    if (form.mediaFiles.length > 0 || form.youtubeLinks.length > 0) {
      const mediaFormData = new FormData();
      form.mediaFiles.forEach((file) => mediaFormData.append('mediaFiles', file));
      mediaFormData.append('youtubeLinks', JSON.stringify(form.youtubeLinks));

      await fetch(`/blogs/${newBlog.id}/media`, {
        method: 'POST',
        body: mediaFormData,
      });
    }

    setForm({ title: '', summary: '', content: '', author: '', mediaFiles: [], youtubeLinks: [] });
    setEditingId(null);
    fetchBlogs();
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Blog Management
      </Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Title"
            fullWidth
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Author"
            fullWidth
            value={form.author}
            onChange={(e) => setForm({ ...form, author: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Summary"
            fullWidth
            multiline
            value={form.summary}
            onChange={(e) => setForm({ ...form, summary: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Content"
            fullWidth
            multiline
            minRows={5}
            value={form.content}
            onChange={(e) => setForm({ ...form, content: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Upload Media Files (Images/Videos)</Typography>
          <input
            type="file"
            multiple
            accept="image/*,video/*"
            onChange={(e) => setForm({ ...form, mediaFiles: Array.from(e.target.files) })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Add YouTube Links</Typography>
          <textarea
            rows="4"
            placeholder="Enter YouTube links, one per line"
            onChange={(e) =>
              setForm({
                ...form,
                youtubeLinks: e.target.value.split('\n').filter((link) => link.trim() !== ''),
              })
            }
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSubmit}>
            {editingId ? 'Update Blog' : 'Add Blog'}
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom>
        Blog Posts
      </Typography>
      {blogs.map((blog) => (
        <Box key={blog.id} sx={{ mb: 3 }}>
          <Typography variant="h6">{blog.title}</Typography>
          <Typography variant="subtitle2">{blog.author}</Typography>
          <Button onClick={() => setEditingId(blog.id)}>Edit</Button>
          <Button onClick={() => console.log(`Delete Blog ID: ${blog.id}`)} color="error">
            Delete
          </Button>
        </Box>
      ))}
    </Box>
  );
}

export default BlogAdmin;
