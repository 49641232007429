import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Paper, Chip, Container, Avatar, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Security, CloudQueue, BuildCircle, Business } from '@mui/icons-material';

function Home() {
  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState('10:00');
  const [visitorCount, setVisitorCount] = useState(12);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        const [minutes, seconds] = prevTime.split(':').map(Number);
        const newSeconds = seconds === 0 ? 59 : seconds - 1;
        const newMinutes = seconds === 0 ? minutes - 1 : minutes;
        return newMinutes < 0 ? '00:00' : `${newMinutes.toString().padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const services = [
    {
      title: 'Cybersecurity',
      description: 'Protect your business from evolving threats with advanced security solutions and 24/7 monitoring.',
      icon: <Security fontSize="large" sx={{ color: '#1976d2' }} />,
      link: '/services/cybersecurity'
    },
    {
      title: 'Cloud Services',
      description: 'Optimize scalability and efficiency with secure cloud migration, management, and optimization.',
      icon: <CloudQueue fontSize="large" sx={{ color: '#1976d2' }} />,
      link: '/services/cloud'
    },
    {
      title: 'IT Support',
      description: 'Ensure uptime with proactive support, help desk services, and comprehensive IT management.',
      icon: <BuildCircle fontSize="large" sx={{ color: '#1976d2' }} />,
      link: '/services/itsupport'
    }
  ];

  const badges = ['HIPAA Compliant', 'SOC 2 Certified', 'NIST Framework', 'PCI DSS Certified'];
  const partners = ['Microsoft', 'Cisco', 'AWS'];

  const testimonials = [
    {
      name: 'Sarah Thompson',
      position: 'CTO, Healthcare Solutions Inc.',
      text: 'Their cybersecurity strategy saved us from a major breach. We trust them with all our IT needs now.'
    },
    {
      name: 'James Carter',
      position: 'CEO, FinTech Innovations',
      text: 'Seamless cloud migration and unbeatable support. We’ve scaled confidently thanks to their expertise.'
    }
  ];

  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
//    fetch('http://localhost:3001/blogs/recent')
    fetch('/api/blogs/recent')
      .then(res => res.json())
      .then(data => setBlogPosts(data))
      .catch(err => console.error('Error fetching blog posts:', err));
  }, []);

  const resources = [
    { title: 'The Ultimate Cybersecurity Checklist', link: '/assets/cybersecurity-checklist.pdf' },
    { title: 'Case Study: Preventing a Major Threat', link: '/assets/case-study.pdf' }
  ];

  return (
    <Box sx={{ bgcolor: '#fff' }}>
      {/* Hero Section */}
      <Box
        sx={{
          textAlign: 'center',
          py: 8,
          px: 2,
          background: '#0d47a1',
          color: 'white',
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
            Secure, Optimize, and Transform Your Business
          </Typography>
          <Typography variant="h6" sx={{ mb: 2, color: '#f1f1f1' }}>
            Enterprise-grade cybersecurity, cloud services, and IT support tailored to your unique needs.
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 4, color: '#ffd700', fontWeight: 'bold' }}>
            Limited-Time Offer: Free Security Assessment expires in {timeLeft} — {visitorCount}+ businesses are exploring this now!
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/booking')}
              sx={{
                bgcolor: '#ff3d00',
                '&:hover': { bgcolor: '#dd2c00' },
                fontSize: '1.2rem',
                py: 1.5,
                px: 4,
              }}
            >
              Schedule My Free Consultation
            </Button>
            <Button
              variant="outlined"
              size="large"
              href="tel:+1-580-422-5109"
              sx={{
                color: 'white',
                borderColor: 'white',
                fontSize: '1.2rem',
                py: 1.5,
                px: 4,
                '&:hover': { borderColor: '#e0e0e0', backgroundColor: 'rgba(255,255,255,0.1)' }
              }}
            >
              Call Now <br /> (580) 422-5109
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Why Your Business Needs This: #f9f9f9 */}
      <Box sx={{ py: 6, px: 2, bgcolor: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 6, color: '#333' }}>
            Why Your Business Needs This
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', maxWidth: '600px', mx: 'auto', mb: 6 }}>
            Modern businesses face evolving threats, complex compliance requirements, and scaling challenges. Our services provide the security, efficiency, and strategic guidance you need to stay ahead.
          </Typography>
        </Container>
      </Box>

      {/* Trust / Compliance & Partners: #fff */}
      <Box sx={{ py: 3, px: 2, bgcolor: '#fff' }}>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2, color: '#333' }}>
            Trusted Compliance & Industry Partners
          </Typography>
          <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
            {badges.map((badge, index) => (
              <Grid item key={index}>
                <Chip
                  label={badge}
                  color="primary"
                  variant="outlined"
                  sx={{ fontSize: '1rem', py: 1, borderRadius: '8px' }}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={4} justifyContent="center">
            {partners.map((partner, index) => (
              <Grid item key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <Avatar sx={{ bgcolor: '#0d47a1', width: 56, height: 56, mb: 1 }}>
                    <Business sx={{ color: 'white' }} />
                  </Avatar>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#333' }}>
                    {partner}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Mission & Values: #f9f9f9 */}
      <Box sx={{ py: 6, px: 2, bgcolor: '#f9f9f9' }}>
        <Container maxWidth="md">
          <Typography variant="h4" sx={{ textAlign: 'center', mb: 4, color: '#333' }}>
            Our Mission & Values
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', mb: 4 }}>
            Our mission is to empower businesses with cutting-edge IT solutions that promote growth, security, and innovation. We value integrity, client success, and long-term partnerships—driven by a commitment to continuous improvement and excellence.
          </Typography>
        </Container>
      </Box>

      {/* Key Metrics: #fff */}
      <Box sx={{ py: 6, px: 2, bgcolor: '#fff' }}>
        <Container maxWidth="md">
          <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', textAlign: 'center', gap: 4 }}>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: '#333' }}>25+</Typography>
              <Typography variant="body1" color="#333">Years of Experience</Typography>
            </Box>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: '#333' }}>100+</Typography>
              <Typography variant="body1" color="#333">Trusted Clients</Typography>
            </Box>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: '#333' }}>99.9%</Typography>
              <Typography variant="body1" color="#333">Uptime Guarantee</Typography>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Our Core Services: #f9f9f9 */}
      <Box sx={{ py: 8, px: 2, bgcolor: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" textAlign="center" gutterBottom color="#333">
            Our Core Services
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', maxWidth: '600px', mx: 'auto', mb: 6 }}>
            From robust cybersecurity measures to scalable cloud solutions, we provide the tools and expertise you need to keep your business safe and thriving.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    textAlign: 'center',
                    transition: '0.3s',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 },
                    color: '#333'
                  }}
                >
                  <Box sx={{ mb: 2 }}>{service.icon}</Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#333', mb: 3 }}>
                    {service.description}
                  </Typography>
                  <Button variant="outlined" color="primary" href={service.link}>Learn More</Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Testimonials: #fff */}
      <Box sx={{ py: 8, px: 2, bgcolor: '#fff' }}>
        <Container maxWidth="md">
          <Typography variant="h4" textAlign="center" gutterBottom color="#333">
            What Industry Leaders Say
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', maxWidth: '600px', mx: 'auto', mb: 6 }}>
            Our clients trust us to deliver reliable, secure IT solutions.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Paper sx={{ p: 4, textAlign: 'center', height: '100%', color: '#333' }}>
                  <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic', color: '#333' }}>
                    "{testimonial.text}"
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                    {testimonial.name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: '#666' }}>
                    {testimonial.position}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Blog: #f9f9f9 */}
      <Box sx={{ py: 8, px: 2, bgcolor: '#f9f9f9' }}>
        <Container maxWidth="md">
          <Typography variant="h4" textAlign="center" gutterBottom color="#333">
            From Our Blog
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', mb: 4 }}>
            Explore our latest insights and expert opinions on the evolving IT landscape.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {blogPosts.map((post, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  sx={{
                    p: 3,
                    transition: '0.3s',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 },
                    height: '100%',
                    color: '#333'
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {post.title}
                  </Typography>
                  <Button variant="outlined" href={`/blog/${post.id}`}>
                    Read More
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Resources & Downloads: #fff */}
      <Box sx={{ py: 8, px: 2, bgcolor: '#fff' }}>
        <Container maxWidth="md">
          <Typography variant="h4" textAlign="center" gutterBottom color="#333">
            Resources & Downloads
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', mb: 4 }}>
            Stay informed with our checklists, case studies, and best practices.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {resources.map((resource, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Paper
                  sx={{
                    p: 3,
                    transition: '0.3s',
                    '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 },
                    height: '100%',
                    color: '#333'
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {resource.title}
                  </Typography>
                  <Button variant="outlined" href={resource.link} target="_blank">
                    Download
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {/* CTA after resources */}
          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}>
              Speak with a Specialist
            </Typography>
            <Typography variant="body1" sx={{ color: '#333', mb: 4 }}>
              Ready to dive deeper into our insights? Connect with our experts to explore solutions tailored to your business.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/booking')}
              sx={{
                bgcolor: '#1976d2',
                '&:hover': { bgcolor: '#115293' },
                fontSize: '1.2rem',
                py: 1.5,
                px: 4,
              }}
            >
              Discuss Further
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Why Choose Us: #f9f9f9 */}
      <Box sx={{ py: 8, px: 2, bgcolor: '#f9f9f9' }}>
        <Container maxWidth="md">
          <Typography variant="h4" textAlign="center" gutterBottom color="#333">
            Why Choose Us
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ color: '#333', maxWidth: '600px', mx: 'auto', mb: 6 }}>
            We stand apart with unmatched expertise, proactive support, and a relentless focus on your success.
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              { title: 'Long-Term Partnership', desc: 'We build lasting relationships, acting as an extension of your team.' },
              { title: 'Expert Guidance', desc: 'Our seasoned professionals provide strategic advice aligned with your growth goals.' },
              { title: 'Compliance Ready', desc: 'We adhere to industry standards, ensuring your data remains secure and compliant.' },
              { title: 'Proactive Monitoring', desc: 'We detect and resolve issues before they impact your operations.' },

            ].map((item, i) => (
              <Grid item xs={12} sm={6} md={3} key={i}>
                <Card sx={{ p: 3, height: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: '#333' }}>{item.title}</Typography>
                    <Typography variant="body2" color="#333">
                      {item.desc}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Home;
