import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import logo from '../assets/logos/logo.png'; // Adjust the path if needed

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <img
            src={logo}
            alt="TRW Technology Group"
            style={{ height: '40px', marginRight: '10px' }}
          />
          <Typography variant="h6">
            TRW Technology Group
          </Typography>
        </Box>

        {/* Navigation */}
        <Box>
          <Button color="inherit" href="/">
            Home
          </Button>
          <Button
            color="inherit"
            onClick={handleMenuOpen}
          >
            Services
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose} component="a" href="/services/cloud">
              Cloud Services
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/compliance">
              Compliance Services
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/cybersecurity">
              Cybersecurity
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/data-analytics">
              Data Analytics & Insights
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/digital-transformation">
              Digital Transformation
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/identity">
              Identity Management
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/infrastructure">
              Infrastructure Management
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/itsupport">
              IT Support
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/software-development">
              Software Development
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component="a" href="/services/virtualciso">
              Virtual CISO | CIO | CTO
            </MenuItem>
          </Menu>
          <Button color="inherit" href="/pricing">
            Pricing
          </Button>
          <Button color="inherit" href="/resources">
            Resources
          </Button>
          <Button color="inherit" href="/blog">
            Blog
          </Button>
          <Button color="inherit" href="/contact">
            Contact
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
