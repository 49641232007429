import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function DigitalTransformation() {
  const benefits = [
    'Modernize outdated processes and infrastructure with cutting-edge technology.',
    'Streamline operations and improve efficiency through automation.',
    'Enhance customer experiences with digital tools and platforms.',
    'Implement scalable solutions to support long-term growth and agility.',
    'Leverage data insights for informed decision-making and strategic planning.',
    'Ensure seamless integration of new systems with existing workflows.'
  ];

  return (
    <ServiceLayout
      title="Digital Transformation Services"
      description="Revolutionize your business with tailored digital transformation strategies. From modernizing processes to integrating innovative technologies, we empower you to adapt and thrive in a digital-first world."
      benefits={benefits}
      isCybersecurity={false} // No scarcity elements for this page
    />
  );
}

export default DigitalTransformation;
