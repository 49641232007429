import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function VirtualCISO() {
  const benefits = [
    'Executive-Level Security Leadership Without the Full-Time Cost',
    'Comprehensive Security Program Development & Management',
    'Risk Assessment & Compliance Management (HIPAA, SOC2, NIST)',
    'Security Policy Development & Implementation',
    'Incident Response Planning & Management',
    'Security Awareness Training Program Development',
    'Vendor Security Assessment & Management',
    'Board & Executive Security Briefings',
    'Security Budget Planning & Optimization',
    'Continuous Security Posture Improvement'
  ];

  return (
    <ServiceLayout
      title="Virtual CISO Services"
      description="Get enterprise-grade security leadership without the enterprise cost. Our Virtual CISO service provides expert security guidance and management for your organization."
      benefits={benefits}
    />
  );
}

export default VirtualCISO;