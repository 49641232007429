import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Typography, Button, TextField, CircularProgress, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Modal from 'react-modal';
import './BookingCalendar.css';


const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const timeSlots = [
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
];

Modal.setAppElement('#root');

function BookingPage() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState({ name: '', email: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    const fetchCalendarEvents = async () => {
      try {
//        const response = await fetch(`http://localhost:3001/availability?date=${selectedDate ? selectedDate.toISOString().split('T')[0] : ''}`);
        const response = await fetch(`/api/availability?date=${selectedDate ? selectedDate.toISOString().split('T')[0] : ''}`);
        if (response.ok) {
          const data = await response.json();
          setCalendarEvents(data.map(event => ({
            start: new Date(event.start),
            end: new Date(event.end),
            title: 'Booked',
          })));
        } else {
          console.error('Failed to fetch calendar events.');
        }
      } catch (error) {
        console.error('Error fetching calendar events:', error);
      }
    };

    if (selectedDate) {
      fetchCalendarEvents();
    }
  }, [selectedDate]);

  const handleDateClick = async (slotInfo) => {
    const selectedDate = slotInfo.start;

    try {
//      const response = await fetch(`http://localhost:3001/availability?date=${selectedDate.toISOString().split('T')[0]}`);
      const response = await fetch(`/api/availability?date=${selectedDate.toISOString().split('T')[0]}`);
      if (response.ok) {
        const data = await response.json();
        setBookedSlots(data);
      } else {
        console.error('Failed to fetch availability.');
      }
    } catch (error) {
      console.error('Error fetching availability:', error);
    }

    setSelectedDate(selectedDate);
    setIsModalOpen(true);
  };

  const handleTimeSlotClick = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  const handleSubmit = async () => {
    if (!selectedDate || !selectedTimeSlot || !contactInfo.name || !contactInfo.email) {
      alert('Please complete all fields.');
      return;
    }

    const startDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTimeSlot.split(/[: ]/);
    startDateTime.setHours(
      selectedTimeSlot.includes('PM') ? parseInt(hours) + 12 : parseInt(hours),
      parseInt(minutes)
    );

    const endDateTime = new Date(startDateTime);
    endDateTime.setMinutes(startDateTime.getMinutes() + 30);

    setLoading(true);
    try {
//      const response = await fetch('http://localhost:3001/add-event', {
      const response = await fetch('/api/add-event', {
          method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: contactInfo.name,
          email: contactInfo.email,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccessMessage(`Booking confirmed! Meeting link: ${data.meetingLink}`);
        setIsModalOpen(false);
        setSelectedTimeSlot('');
        setCalendarEvents(prevEvents => [
          ...prevEvents,
          { start: startDateTime, end: endDateTime, title: 'Booked' },
        ]);
      } else {
        alert('Failed to create booking. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isTimeSlotBooked = (timeSlot) => {
    const [hours, minutes] = timeSlot.split(/[: ]/);
    const time = new Date(selectedDate);
    time.setHours(
      timeSlot.includes('PM') ? parseInt(hours) + 12 : parseInt(hours),
      parseInt(minutes)
    );

    return bookedSlots.some(
      (slot) => new Date(slot.start).getTime() <= time.getTime() && new Date(slot.end).getTime() > time.getTime()
    );
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Schedule an Appointment
      </Typography>
      <Typography textAlign="center" sx={{ marginBottom: '20px' }}>
        Choose a time that works for you.
      </Typography>

      <Calendar
        localizer={localizer}
        selectable
        onSelectSlot={handleDateClick}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: '20px 0' }}
      />

      {successMessage && (
        <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="h6" color="green">
            {successMessage}
          </Typography>
        </Box>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Select Time Slot"
        style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1000,
    },
    content: {
      maxWidth: '500px',
      margin: 'auto',
      padding: '20px',
      borderRadius: '10px',
      position: 'relative',
      zIndex: 1001,
    },
  }}
>        <Typography variant="h5" gutterBottom>
          Select a Time Slot for {format(selectedDate, 'PP')}
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', mb: 3 }}>
    {timeSlots.map((time, index) => (
          <Button
              key={index}
              variant={time === selectedTimeSlot ? 'contained' : 'outlined'}
              color={time === selectedTimeSlot ? 'primary' : 'default'}
              disabled={isTimeSlotBooked(time) || (loading && time === selectedTimeSlot)}
              onClick={() => handleTimeSlotClick(time)}
              sx={{ minWidth: '100px' }}
            >
              {loading && time === selectedTimeSlot ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                time
              )}
            </Button>
          ))}
        </Box>
        <Box className="modal-form">
        <TextField
          label="Your Name"
          name="name"
          value={contactInfo.name}
          onChange={handleInputChange}
          fullWidth
          required
          className="form-field"
        />
        <TextField
          label="Your Email"
          name="email"
          type="email"
          value={contactInfo.email}
          onChange={handleInputChange}
          fullWidth
          required
          className="form-field"
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          type="tel"
          value={contactInfo.phoneNumber}
          onChange={handleInputChange}
          fullWidth
          required
          className="form-field"
        />
        <TextField
          label="Company Name"
          name="company"
          value={contactInfo.company}
          onChange={handleInputChange}
          fullWidth
          required
          className="form-field"
        />
        <TextField
          label="Job Title"
          name="jobTitle"
          value={contactInfo.jobTitle}
          onChange={handleInputChange}
          fullWidth
          className="form-field"
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="service-interest-label">Service Interest</InputLabel>
          <Select
            labelId="service-interest-label"
            name="serviceInterest"
            value={contactInfo.serviceInterest}
            onChange={handleInputChange}
          >
            <MenuItem value="Cybersecurity">Cybersecurity</MenuItem>
            <MenuItem value="Software Development">Software Development</MenuItem>
            <MenuItem value="IT Consulting">IT Consulting</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Referral Code"
          name="referralCode"
          value={contactInfo.referralCode}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Time Zone"
          name="timezone"
          value={contactInfo.timezone}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
            <Box className="checkbox-field">
      <input
        type="checkbox"
        name="optIn"
        checked={contactInfo.optIn}
        onChange={(e) =>
          setContactInfo({ ...contactInfo, optIn: e.target.checked })
        }
      />
      <Typography variant="body2">
        I agree to receive promotional emails
      </Typography>
    </Box>
          </Box>
          <Box className="modal-footer">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirm Booking'}
          </Button>
          </Box>
      </Modal>
    </Box>
  );
}

export default BookingPage;
