import React from 'react';
import BookingCalendar from '../components/BookingCalendar';

function Booking() {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Schedule an Appointment</h1>
      <p>Choose a time that works for you, and we’ll confirm your booking shortly.</p>
      <BookingCalendar />
    </div>
  );
}

export default Booking;
