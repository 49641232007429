import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function InfrastructureManagement() {
  const benefits = [
    'Comprehensive design, deployment, and management of IT infrastructure.',
    'Seamless integration with existing systems and workflows.',
    'Optimized network performance and reliability.',
    'Proactive monitoring and maintenance to minimize downtime.',
    'Scalable solutions to adapt to your business growth.',
    'Enhanced security to protect critical infrastructure.'
  ];

  return (
    <ServiceLayout
      title="Infrastructure Management"
      description="Ensure your IT infrastructure is secure, reliable, and optimized for success. Our infrastructure management solutions offer end-to-end support, from design and deployment to proactive maintenance and scalability."
      benefits={benefits}
      isCybersecurity={false} // No scarcity elements for this page
    />
  );
}

export default InfrastructureManagement;
