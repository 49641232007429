import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Booking from './pages/Booking';
import Resources from './pages/Resources';
import ResourceViewer from './pages/ResourceViewer';
import CloudServices from './pages/services/CloudServices';
import ComplianceServices from './pages/services/ComplianceServices';
import Cybersecurity from './pages/services/Cybersecurity';
import DataAnalytics from './pages/services/DataAnalytics';
import DigitalTransformation from './pages/services/DigitalTransformation';
import IdentityManagement from './pages/services/IdentityManagement';
import InfrastructureManagement from './pages/services/InfrastructureManagement';
import ITSupport from './pages/services/ITSupport';
import SoftwareDevelopment from './pages/services/SoftwareDevelopment';
import VirtualCISO from './pages/services/VirtualCISO';
import BlogAdmin from './pages/admin/BlogAdmin';
import BlogList from './pages/BlogList';
import BlogDetail from './pages/BlogDetail';

function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem('authToken'); // Example check
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services/cloud" element={<CloudServices />} />
        <Route path="/services/compliance" element={<ComplianceServices />} />
        <Route path="/services/cybersecurity" element={<Cybersecurity />} />
        <Route path="/services/data-analytics" element={<DataAnalytics />} />
        <Route path="/services/digital-transformation" element={<DigitalTransformation />} />
        <Route path="/services/identity" element={<IdentityManagement />} />
        <Route path="/services/infrastructure" element={<InfrastructureManagement />} />
        <Route path="/services/itsupport" element={<ITSupport />} />
        <Route path="/services/software-development" element={<SoftwareDevelopment />} />
        <Route path="/services/virtualciso" element={<VirtualCISO />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/resources" element={<Resources />} />;
        <Route path="/resource-viewer" element={<ResourceViewer />} />
        <Route path="/admin/blogs" element={ <ProtectedRoute> <BlogAdmin /> </ProtectedRoute>} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
