import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function DataAnalytics() {
  const benefits = [
    'Convert raw data into actionable business insights.',
    'Custom dashboards tailored to your specific KPIs.',
    'Advanced analytics for forecasting and decision-making.',
    'Real-time reporting for enhanced operational efficiency.',
    'Integration with existing systems to streamline data flow.',
    'Secure and compliant data handling to protect sensitive information.',
  ];

  return (
    <ServiceLayout
      title="Data Analytics & Insights"
      description="Harness the power of data to drive smarter decisions and elevate your business performance. Our solutions transform complex data into easy-to-understand insights, empowering your team to act with confidence."
      benefits={benefits}
    />
  );
}

export default DataAnalytics;
