import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Button, Divider, Chip, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Check, Shield, AlertTriangle, Award, Star } from 'lucide-react';
import PricingCalculator from '../components/PricingCalculator'; // Ensure this points to the correct path

function Pricing() {
  const [timeLeft, setTimeLeft] = useState("10:00");

  const plans = [
    {
      name: "Essential Security",
      price: "$1,999",
      period: "/month",
      subtitle: "For Small Businesses",
      features: [
        "24/7 Basic Monitoring",
        "Firewall Management",
        "Email Security",
        "Basic Threat Detection",
        "Quarterly Security Review",
        "Up to 25 Users"
      ],
      highlight: false,
      btnText: "Get Started"
    },
    {
      name: "Advanced Protection",
      price: "$4,999",
      period: "/month",
      subtitle: "Most Popular for Mid-Size Companies",
      features: [
        "Everything in Essential, plus:",
        "Advanced Threat Detection",
        "SIEM Implementation",
        "Monthly Security Assessment",
        "Incident Response Team",
        "Employee Security Training",
        "Up to 100 Users",
        "Compliance Management"
      ],
      highlight: true,
      btnText: "Secure Your Business"
    },
    {
      name: "Enterprise Shield",
      price: "$9,999",
      period: "/month",
      subtitle: "For Large Organizations",
      features: [
        "Everything in Advanced, plus:",
        "Custom Security Architecture",
        "24/7 SOC Team",
        "Advanced Breach Protection",
        "Zero-Day Threat Prevention",
        "Executive Security Training",
        "Unlimited Users",
        "Dedicated CISO"
      ],
      highlight: false,
      btnText: "Contact Us"
    },
    {
      name: "Ultimate Defense",
      price: "From $19,999",
      period: "/month",
      subtitle: "For Enterprise & High-Risk Organizations",
      features: [
        "Everything in Enterprise Shield, plus:",
        "Custom Security Operations Center",
        "AI-Powered Threat Hunting",
        "Red Team Operations",
        "Source Code Security Audit",
        "Hardware Security Modules",
        "Military-Grade Encryption",
        "Global Threat Intelligence"
      ],
      highlight: false,
      btnText: "Schedule Consultation"
    }
  ];

  return (
    <Box sx={{ py: 8, px: 4, bgcolor: '#f5f5f5' }}>
      {/* Alert Banner */}
      <Box sx={{ 
        bgcolor: '#ff3d00', 
        color: 'white', 
        p: 2, 
        mb: 6, 
        textAlign: 'center',
        borderRadius: 1
      }}>
        <AlertTriangle className="inline-block mr-2" />
        <Typography variant="subtitle1" component="span">
          Limited Time Offer: Lock in 2024 pricing for {timeLeft}
        </Typography>
      </Box>

      {/* Header Section */}
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <Typography variant="h3" gutterBottom>
          Protect Your Business
        </Typography>
        <Typography variant="h6" color="textSecondary" sx={{ mb: 4 }}>
          60% of companies go out of business within 6 months of a cyber attack.
          Don't be one of them.
        </Typography>
      </Box>

      {/* Pricing Grid */}
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan, index) => (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <Paper 
              elevation={plan.highlight ? 12 : 4} 
              sx={{ 
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                transition: '0.3s',
                transform: plan.highlight ? 'scale(1.05)' : 'none',
                border: plan.highlight ? '2px solid #2196f3' : 'none',
                '&:hover': {
                  transform: plan.highlight ? 'scale(1.07)' : 'scale(1.02)'
                }
              }}
            >
              {plan.highlight && (
                <Chip 
                  label="RECOMMENDED" 
                  color="primary" 
                  sx={{ 
                    position: 'absolute',
                    top: -15,
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}
                />
              )}

              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                {plan.name}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                {plan.subtitle}
              </Typography>
              
              <Box sx={{ my: 3 }}>
                <Typography variant="h4" component="span">
                  {plan.price}
                </Typography>
                <Typography variant="subtitle1" component="span" color="textSecondary">
                  {plan.period}
                </Typography>
              </Box>

              <Divider sx={{ my: 2 }} />

              <List sx={{ flexGrow: 1, mb: 3 }}>
                {plan.features.map((feature, idx) => (
                  <ListItem key={idx} sx={{ py: 1 }}>
                    <ListItemIcon>
                      <Check color={plan.highlight ? '#2196f3' : 'grey'} size={20} />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>

              <Button 
                variant={plan.highlight ? "contained" : "outlined"}
                color={plan.highlight ? "primary" : "primary"}
                size="large"
                href="/booking"
                fullWidth
                sx={{ 
                  mt: 'auto',
                  py: 2,
                  fontSize: '1.1rem'
                }}
              >
                {plan.btnText}
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Trust Indicators */}
      <Box sx={{ mt: 10, textAlign: 'center' }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Shield size={32} className="mb-2" />
            <Typography variant="h6">SOC 2 Certified</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Award size={32} className="mb-2" />
            <Typography variant="h6">HIPAA Compliant</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Star size={32} className="mb-2" />
            <Typography variant="h6">Top-Rated Security</Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Pricing Calculator Section */}
      <Box sx={{ mt: 10 }}>
        <Typography variant="h4" textAlign="center" sx={{ mb: 4 }}>
          Customize Your Plan
        </Typography>
        <PricingCalculator />
      </Box>

      {/* Money Back Guarantee */}
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="subtitle1" color="textSecondary">
          30-Day Money Back Guarantee • No Long-Term Contracts • Cancel Anytime
        </Typography>
      </Box>
    </Box>
  );
}

export default Pricing;
