import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function Cybersecurity() {
  const benefits = [
    'Protect against evolving cyber threats.',
    'Ensure compliance with industry regulations.',
    'Round-the-clock monitoring for peace of mind.',
  ];

  return (
    <ServiceLayout
      title="Cybersecurity Solutions"
      description="Comprehensive security for your business in a connected world."
      benefits={benefits}
      isCybersecurity={true}
    />
  );
}

export default Cybersecurity;
