import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Switch, FormControlLabel, Divider } from '@mui/material';

function PricingCalculator() {
  // State for input values
  const [numUsers, setNumUsers] = useState(10);
  const [numSystems, setNumSystems] = useState(5);
  const [numServers, setNumServers] = useState(2);
  const [addOns, setAddOns] = useState({
    monitoring: false,
    patchManagement: false,
    complianceAudits: false,
    incidentResponse: false,
  });

  // Base prices
  const basePerUser = 50;
  const basePerSystem = 30;
  const basePerServer = 100;

  // Add-on prices
  const addOnPrices = {
    monitoring: 200,
    patchManagement: 150,
    complianceAudits: 300,
    incidentResponse: 500,
  };

  // Calculate total
  const calculateTotal = () => {
    let total =
      numUsers * basePerUser +
      numSystems * basePerSystem +
      numServers * basePerServer;

    // Add cost of active add-ons
    Object.keys(addOns).forEach((addOn) => {
      if (addOns[addOn]) {
        total += addOnPrices[addOn];
      }
    });

    return total;
  };

  return (
    <Box
      sx={{
        p: 4,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: '#fff',
        maxWidth: '600px',
        mx: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Pricing Calculator
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, color: 'textSecondary' }}>
        Customize your services and get an estimated monthly cost.
      </Typography>

      <Divider sx={{ my: 3 }} />

      {/* Inputs for Users, Systems, and Servers */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Number of Users"
            type="number"
            value={numUsers}
            onChange={(e) => setNumUsers(parseInt(e.target.value, 10) || 0)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Number of Systems"
            type="number"
            value={numSystems}
            onChange={(e) => setNumSystems(parseInt(e.target.value, 10) || 0)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Number of Servers"
            type="number"
            value={numServers}
            onChange={(e) => setNumServers(parseInt(e.target.value, 10) || 0)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      {/* Add-On Toggle Sliders */}
      <Box sx={{ textAlign: 'left', mb: 4 }}>
        {Object.keys(addOns).map((addOn) => (
          <FormControlLabel
            key={addOn}
            control={
              <Switch
                checked={addOns[addOn]}
                onChange={(e) =>
                  setAddOns({ ...addOns, [addOn]: e.target.checked })
                }
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {addOn.replace(/([A-Z])/g, ' $1')} - $
                {addOnPrices[addOn].toLocaleString()}
              </Typography>
            }
            sx={{ display: 'block', mb: 1 }}
          />
        ))}
      </Box>

      <Divider sx={{ my: 3 }} />

      {/* Total Price */}
      <Typography variant="h6" gutterBottom>
        Estimated Monthly Cost
      </Typography>
      <Typography variant="h4" color="primary" gutterBottom>
        ${calculateTotal().toLocaleString()}
      </Typography>
    </Box>
  );
}

export default PricingCalculator;
