import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function ITSupport() {
  const benefits = [
    '24/7/365 Help Desk Support with 15-Minute Response Time',
    'Proactive System Monitoring & Maintenance',
    'Network Infrastructure Management & Security',
    'Cloud Services & Microsoft 365 Support',
    'Asset Management & Lifecycle Planning',
    'Automated Patch Management & Updates',
    'Business Continuity & Disaster Recovery',
    'On-Site Technical Support When Needed',
    'Mobile Device Management & Security',
    'Vendor Management & Coordination'
  ];

  return (
    <ServiceLayout
      title="24/7 Enterprise IT Support"
      description="Keep your business running smoothly with our comprehensive IT support solutions. From immediate help desk response to proactive system maintenance."
      benefits={benefits}
    />
  );
}

export default ITSupport;