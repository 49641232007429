import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, Paper, Chip, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ServiceLayout({ title, description, benefits = [], isCybersecurity }) {
  const [timeLeft, setTimeLeft] = useState('10:00');
  const [visitorCount, setVisitorCount] = useState(3);
  const navigate = useNavigate();

  // Countdown logic (only for cybersecurity)
  useEffect(() => {
    if (!isCybersecurity) return;
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const [minutes, seconds] = prevTime.split(':').map(Number);
        const newSeconds = seconds === 0 ? 59 : seconds - 1;
        const newMinutes = seconds === 0 ? minutes - 1 : minutes;
        return newMinutes < 0 ? '00:00' : `${newMinutes.toString().padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [isCybersecurity]);

  // Social proof data
  const testimonials = [
    {
      name: 'Sarah Thompson',
      position: 'CTO, Healthcare Solutions Inc.',
      text: 'Implementing their security solutions saved us from what could have been a catastrophic breach.',
    },
  ];

  // Compliance and certification badges
  const badges = [
    'HIPAA Compliant',
    'SOC 2 Certified',
    'NIST Framework',
    'PCI DSS Certified',
  ];

  // Risk statistics (for cybersecurity services only)
  const riskStats = isCybersecurity
    ? [
        '60% of small businesses close within 6 months of a cyber attack',
        'Average cost of a data breach: $4.35 million',
        'Healthcare sector faces 1,410+ attacks weekly',
      ]
    : [];

  // Fallback if no benefits are provided
  const defaultBenefits = [
    'Enhanced Security',
    'Compliance with Industry Standards',
    'Improved Operational Efficiency',
  ];

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          textAlign: 'center',
          py: 8,
          px: 2,
          background: '#0d47a1',
          color: 'white',
        }}
      >
        {isCybersecurity && (
          <Alert severity="error" sx={{ maxWidth: 800, mx: 'auto', mb: 4 }}>
            {visitorCount} other companies in your industry are currently viewing this solution.
          </Alert>
        )}

        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="h5" sx={{ mb: 4, color: '#f1f1f1' }}>
          {description}
        </Typography>

        {isCybersecurity && (
          <Typography variant="subtitle1" sx={{ color: '#ffd700', mb: 4 }}>
            Limited Time Security Assessment Offer Expires in: {timeLeft}
          </Typography>
        )}

        <Button
          variant="contained"
          size="large"
          onClick={() => navigate('/contact')}
          sx={{
            bgcolor: isCybersecurity ? '#ff3d00' : '#1976d2',
            '&:hover': { bgcolor: isCybersecurity ? '#dd2c00' : '#115293' },
            fontSize: '1.2rem',
            py: 1.5,
            px: 4,
          }}
        >
          {isCybersecurity
            ? 'Get Your Free Security Assessment Now'
            : 'Learn More About This Service'}
        </Button>
      </Box>

      {/* Benefits Section */}
      <Box sx={{ py: 6, px: 4, bgcolor: '#f9f9f9' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          Why Your Business Needs This
        </Typography>
        <Grid container spacing={4}>
          {(benefits.length > 0 ? benefits : defaultBenefits).map((benefit, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  transition: '0.3s',
                  '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 },
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {benefit.title || benefit}
                </Typography>
                {benefit.description && (
                  <Typography variant="body2" color="text.secondary">
                    {benefit.description}
                  </Typography>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Risk Statistics (Cybersecurity Only) */}
      {riskStats.length > 0 && (
        <Box sx={{ py: 6, px: 4, bgcolor: '#1a237e', color: 'white' }}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
            Industry Risk Factors
          </Typography>
          <Grid container spacing={4}>
            {riskStats.map((stat, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography>{stat}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Compliance Section */}
      <Box sx={{ py: 6, px: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
          Compliance & Certifications
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
          {badges.map((badge, index) => (
            <Chip
              key={index}
              label={badge}
              color="primary"
              variant="outlined"
              sx={{ fontSize: '1rem', py: 2 }}
            />
          ))}
        </Box>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 6, px: 4, bgcolor: '#f5f5f5' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 6 }}>
          What Industry Leaders Say
        </Typography>
        <Grid container justifyContent="center">
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={8} key={index}>
              <Paper sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
                  "{testimonial.text}"
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {testimonial.position}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ServiceLayout;
