import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function CloudServices() {
  const benefits = [
    'Secure and seamless cloud migration tailored to your business.',
    'Optimized cloud infrastructure for performance and cost efficiency.',
    'Scalable solutions to accommodate your growing needs.',
    'Enhanced collaboration through cloud-based tools and platforms.',
    '24/7 monitoring and management of your cloud environment.',
    'Expert guidance on choosing the right cloud strategy for your business.'
  ];

  return (
    <ServiceLayout
      title="Cloud Services"
      description="Harness the power of the cloud to boost productivity, scalability, and security. Our comprehensive cloud services provide seamless migration, optimization, and ongoing support for your business infrastructure."
      benefits={benefits}
      isCybersecurity={false} // No scarcity elements for this page
    />
  );
}

export default CloudServices;
