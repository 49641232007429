import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

function ResourceViewer() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const file = queryParams.get('file');

  if (!file) {
    return (
      <Box sx={{ py: 6, px: 2, textAlign: 'center' }}>
        <Typography variant="h4" color="error">
          No resource specified
        </Typography>
      </Box>
    );
  }

  const isPDF = file.endsWith('.pdf');
  const isVideo = file.endsWith('.mp4') || file.endsWith('.webm');

  return (
    <Box sx={{ py: 6, px: 2 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Viewing Resource
      </Typography>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        {isPDF && (
          <iframe
            src={file}
            style={{
              width: '100%',
              height: '80vh',
              border: 'none',
            }}
            title="PDF Viewer"
          ></iframe>
        )}
        {isVideo && (
          <video
            controls
            style={{ width: '100%', maxHeight: '80vh' }}
          >
            <source src={file} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {!isPDF && !isVideo && (
          <Typography variant="h6" color="text.secondary">
            The selected resource cannot be previewed. Please download it to view.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default ResourceViewer;
