import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Resources() {
  const navigate = useNavigate();

  const resources = [
    {
      title: 'The Ultimate Cybersecurity Checklist',
      description: 'A step-by-step guide to securing your business.',
      link: '/assets/cybersecurity-checklist.pdf',
    },
    {
      title: 'Case Study: How We Protected a Business from a Major Threat',
      description: 'Learn how we safeguarded a client against data breaches.',
      link: '/assets/case-study.pdf',
    },
    {
      title: 'Understanding Managed IT Services',
      description: 'Discover the benefits of outsourcing IT management.',
      link: '/assets/managed-it-guide.pdf',
    },
  ];

  return (
    <Box sx={{ py: 6, px: 2 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Resource Library
      </Typography>
      <Grid container spacing={4}>
        {resources.map((resource, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
              <Typography variant="h6" gutterBottom>
                {resource.title}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {resource.description}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate(`/resource-viewer?file=${encodeURIComponent(resource.link)}`)}
              >
                View Resource
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Resources;
