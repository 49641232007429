import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link for navigation

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
//      const response = await fetch('http://localhost:3001/categories');
      const response = await fetch('/api/categories');
      const data = await response.json();
      setCategories(data);
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      const params = new URLSearchParams();
      if (search) params.append('search', search);
      if (selectedCategory) params.append('category_id', selectedCategory);

//      const response = await fetch(`http://localhost:3001/blogs?${params.toString()}`);
      const response = await fetch(`/api/blogs?${params.toString()}`);
      const data = await response.json();
      setBlogs(data);
    };

    fetchBlogs();
  }, [search, selectedCategory]);

  return (
    <Box sx={{ py: 6, px: 2 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Blog List
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, gap: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          displayEmpty
          variant="outlined"
        >
          <MenuItem value="">
            <em>All Categories</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Grid container spacing={4}>
        {blogs.map((blog) => (
          <Grid item xs={12} sm={6} md={4} key={blog.id}>
            <Link to={`/blog/${blog.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: '8px', cursor: 'pointer' }}>
                <Typography variant="h6" gutterBottom>
                  {blog.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {blog.summary}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Category: {categories.find((cat) => cat.id === blog.category_id)?.name || 'Uncategorized'}
                </Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default BlogList;
