import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';

function BlogDetail() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
//        const response = await fetch(`http://localhost:3001/blogs/${id}`);
        const response = await fetch(`/api/blogs/${id}`);
        const data = await response.json();
        setBlog(data);
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [id]);

  const formatContent = (content) => {
    return content.split('\n').map((line, index) => {
      // Detect lines with `###` headers
      if (line.startsWith('### ')) {
        return (
          <Typography
            key={index}
            variant="h5"
            sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}
          >
            {line.replace('### ', '').trim()}
          </Typography>
        );
      }

      // Detect lines surrounded by `**` for bold formatting
      if (line.startsWith('**') && line.endsWith('**') && !line.includes(':')) {
        return (
          <Typography
            key={index}
            variant="h6"
            sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}
          >
            {line.slice(2, -2).trim()} {/* Remove the `**` */}
          </Typography>
        );
      }

      // Detect lines like `**Bold Text:**` and retain the colon
      if (line.startsWith('**') && line.includes(':') && line.endsWith('**')) {
        const formattedLine = line.slice(2, -2).trim(); // Remove `**`
        return (
          <Typography
            key={index}
            variant="h6"
            sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}
          >
            {formattedLine}
          </Typography>
        );
      }

      // Normal paragraph text
      return (
        <Typography key={index} variant="body1" sx={{ mb: 2, lineHeight: 1.8 }}>
          {line.trim()}
        </Typography>
      );
    });
  };

  if (!blog) {
    return (
      <Box sx={{ py: 6, px: 2, textAlign: 'center' }}>
        <Typography variant="h5" color="error">
          Blog post not found.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', py: 6, px: 3 }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        {blog.title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{ textAlign: 'center' }}>
        By {blog.author} on {new Date(blog.date).toLocaleDateString()}
      </Typography>
      <Divider sx={{ my: 4 }} />
      {formatContent(blog.content)}
      {blog.media && blog.media.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Media
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {blog.media.map((item, index) => {
              if (item.media_type === 'image') {
                return (
                  <Box key={index} sx={{ width: '100%', maxWidth: '400px', margin: 'auto' }}>
                    <img
                      src={item.media_path}
                      alt="Blog Media"
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                      }}
                    />
                  </Box>
                );
              } else if (item.media_type === 'video') {
                return (
                  <Box key={index} sx={{ width: '100%', maxWidth: '400px', margin: 'auto' }}>
                    <video
                      controls
                      src={item.media_path}
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                      }}
                    />
                  </Box>
                );
              } else if (item.media_type === 'youtube') {
                return (
                  <Box key={index} sx={{ width: '100%', maxWidth: '400px', margin: 'auto' }}>
                    <iframe
                      width="100%"
                      height="300"
                      src={item.media_path}
                      title="YouTube Video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                      }}
                    ></iframe>
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default BlogDetail;
