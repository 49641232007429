import React, { useState } from 'react';
import { Box, Typography, Button, TextField, MenuItem, Container, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Contact() {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    company: '',
    phone: '',
    email: '',
    purpose: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
//      const response = await fetch('http://localhost:3001/contact', {
      const response = await fetch('/api/contact', {
          method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errData = await response.json();
        console.error('Error submitting form:', errData);
        alert('There was a problem submitting your request. Please try again later.');
      } else {
        const data = await response.json();
        console.log('Form Submitted:', data);
        alert('Thank you for reaching out. We will get back to you shortly.');
        setFormData({
          name: '',
          title: '',
          company: '',
          phone: '',
          email: '',
          purpose: '',
          message: '',
        });
        navigate('/'); // redirect after submission if desired
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('A network error occurred. Please check your connection and try again.');
    }
  };

  return (
    <Box sx={{ py: 6 }}>
      <Container maxWidth="sm">
        <Typography variant="h4" textAlign="center" gutterBottom sx={{ mb: 4 }}>
          Contact Us
        </Typography>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="body1" sx={{ mb: 4, color: '#333' }} textAlign="center">
            Have questions, need help, or want to learn more? Fill out the form and we'll be in touch shortly.
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Your Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              placeholder="(555) 123-4567"
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Your Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              placeholder="you@example.com"
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              select
              label="Purpose of Contact"
              name="purpose"
              value={formData.purpose}
              onChange={handleChange}
              required
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 3 }}
            >
              <MenuItem value="Support">Support</MenuItem>
              <MenuItem value="Sales">Sales</MenuItem>
              <MenuItem value="General Inquiry">General Inquiry</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Your Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              required
              placeholder="How can we help you?"
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 4 }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ py: 1.5, fontSize: '1rem' }}>
              Submit
            </Button>
          </form>
        </Paper>
      </Container>
    </Box>
  );
}

export default Contact;
