import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function SoftwareDevelopment() {
  const benefits = [
    'Custom software tailored to your specific business needs.',
    'Legacy system modernization to keep your infrastructure updated.',
    'Scalable solutions that grow with your business.',
    'Cutting-edge application development using the latest technologies.',
    'Enhanced efficiency through automation and process optimization.',
    'Dedicated support and maintenance for long-term success.'
  ];

  return (
    <ServiceLayout
      title="Software Development Services"
      description="Leverage our expertise to create software solutions tailored to your unique challenges. From modernizing legacy systems to building scalable, cutting-edge applications, we deliver results that drive business growth."
      benefits={benefits}
      isCybersecurity={false} // No scarcity elements for this page
    />
  );
}

export default SoftwareDevelopment;
