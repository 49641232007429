import React from 'react';
import ServiceLayout from '../../components/ServiceLayout';

function IdentityManagement() {
  const benefits = [
    'Secure access control to protect sensitive data and systems.',
    'Simplify user authentication with Single Sign-On (SSO) solutions.',
    'Ensure compliance with identity management standards and regulations.',
    'Protect privileged accounts with enhanced security measures.',
    'Automate user provisioning and de-provisioning to streamline operations.',
    'Reduce the risk of insider threats through advanced monitoring and analytics.'
  ];

  return (
    <ServiceLayout
      title="Identity Management Services"
      description="Protect your business with advanced identity management solutions. From secure access control to privileged account protection, we help you safeguard your organization while enhancing operational efficiency."
      benefits={benefits}
      isCybersecurity={false} // No scarcity elements for this page
    />
  );
}

export default IdentityManagement;
